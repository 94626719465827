import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import SanityLink from "../components/SanityLink"

export default function DirectoryMenu({ cols }) {
  const data = useStaticQuery(graphql`
    query DirectoryMenuQuery {
      menu: sanitySettingsGeneral {
        directoryMenu {
          items {
            ...navItems
          }
        }
      }
    }
  `)

  const menu = data.menu.directoryMenu.items

  var gridCols = "md:grid-cols-4 lg:grid-cols-8"

  if (cols === 8) {
    gridCols = "md:grid-cols-4 lg:grid-cols-8"
  } else if (cols === 4) {
    gridCols = "md:grid-cols-4"
  } else if (cols === 2) {
    gridCols = "md:grid-cols-2"
  }

  return (
    <>
      <ul className={`grid grid-cols-2 gap-2 ` + gridCols}>
        {menu.map(link => {
          return (
            <li key={link._key}>
              <SanityLink
                link={link.navigationItemUrl}
                className="directory-link block border border-transparent group"
              >
                <div className="p-4 aspect-video flex items-center justify-center relative">
                  {link.menuImage && (
                    <div className="absolute inset-0 opacity-50 group-hover:opacity-100 transition duration-500">
                      <GatsbyImage
                        image={link.menuImage.asset.gatsbyImageData}
                        alt={
                          link.menuImage.asset.altText ||
                          link.title + " menu image"
                        }
                        className="h-full w-full object-cover "
                      />
                    </div>
                  )}
                  <span className="text-center relative h6 text-black group-hover:text-white transition duration-500">
                    {link.text}
                  </span>
                </div>
              </SanityLink>
            </li>
          )
        })}
      </ul>
    </>
  )
}
