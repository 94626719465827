import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

export default function ListingCard({ post }) {
  var prependUrl = ""

  if (post._type === "listings") {
    prependUrl = "local-traders/"
  } else if (post._type === "events") {
    prependUrl = "events/"
  } else if (post._type === "news") {
    prependUrl = "news/"
  }

  return (
    <Link to={`/` + prependUrl + post.slug.current}>
      <div className="aspect-video mb-3 bg-grey">
        {post.content?.featureImage && (
          <GatsbyImage
            image={post.content.featureImage.asset.gatsbyImageData}
            alt={
              post.content.featureImage.asset.altText ||
              post.title + " feature image"
            }
            className="h-full w-full object-cover "
          />
        )}
      </div>
      <h2 className="h6 mb-0">{post.title}</h2>
      {post.content?.subHeading && (
        <p className="text-sm">{post.content.subHeading}</p>
      )}
    </Link>
  )
}
