import React, { useState, useEffect } from "react"
import ListingCard from "./ListingCard"

export default function LoadMore({ posts, paginate }) {
  // Array of all news articles
  const allPosts = posts

  const postNum = paginate || 6

  // State for the list
  const [list, setList] = useState([...allPosts.slice(0, postNum)])

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false)

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(allPosts.length > postNum)

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true)
  }

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < allPosts.length
      const nextResults = isMore
        ? allPosts.slice(currentLength, currentLength + postNum)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < allPosts.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-8">
        {list?.map(post => {
          return <ListingCard key={post._id} post={post} />
        })}
      </div>

      {hasMore && (
        <div className="py-16 flex justify-center">
          <button onClick={handleLoadMore} className="btn btn--black">
            Load More
          </button>
        </div>
      )}
    </>
  )
}
